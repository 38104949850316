<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="primary"
      dark
      elevation="1"
      height="80"
    >
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        style="margin-right: 16px"
        @click="drawer = !drawer"
      />

      <base-img
        style="cursor: pointer;"
        :src="require('@/assets/imgs/logo.png')"
        max-height="100%"
        max-width="215px"
        contain
        @click="$router.push('/')"
      />

      <v-spacer />

      <div>
        <v-tabs
          dark
          background-color="primary"
          color="accent"
          class="hidden-md-and-down"
          optional
          v-model="currentRoute"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item"
            :ripple="false"
            class="font-weight-bold"
            :class="$route.path !== '/' ? `item-${i}` : null"
            min-width="96"
            text
          >
            {{ $i18n.t(item.view) }}
          </v-tab>
        </v-tabs>
      </div>
      <!-- v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            text
            dark
            v-bind="attrs"
            v-on="on"
          >
            <h3>{{ $i18n.locale | simpleLang }}</h3>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item
            v-for="(item, index) in languages"
            :key="index"
            style="text-align: center"
            class="clickable"
            @click="setLang(item)"
          >
            <v-list-item-title>
              <b>{{ item | simpleLang }}</b>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu -->
    </v-app-bar>
    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {

    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    filters: {
      simpleLang (lang) {
        if (lang.indexOf('-') > 0) {
          lang = lang.split('-')[0]
        }
        return lang.toUpperCase()
      },
    },

    data: () => ({
      currentRoute: 0,
      drawer: null,
      items: [
        { name: 'Home', view: 'home' },
        { name: 'Interventi', view: 'interventi' },
        { name: 'Sicurezza', view: 'sicurezza' },
        { name: 'Eco-Friendly', view: 'eco-friendly' },
      ],
    }),

    computed: {
      languages () {
        return this.$root.$i18n.availableLocales.filter((l) => l !== this.$root.$i18n.locale.split('-')[0])
      },
    },

    methods: {
      setLang (lang) {
        this.$root.$i18n.locale = lang
        sessionStorage.lang = lang
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none

  .clickable
    &:hover
      cursor: pointer !important
  .item-0
    color: rgba(255, 255, 255, 0.6) !important

</style>
<i18n>
{
  "en": {
    "eco-friendly": "Eco-Friendly",
    "home": "Home",
    "interventi": "Method",
    "progetto": "Project",
    "servizi": "Services",
    "sicurezza": "Security",
    "trasparenza": "Transparency"
	},
  "it": {
    "eco-friendly": "Eco-Friendly",
    "home": "Home",
    "interventi": "Modalità",
    "progetto": "Progetto",
    "servizi": "Servizi",
    "sicurezza": "Sicurezza",
    "trasparenza": "Trasparenza"
	}
}
</i18n>
